import is_bot from "isbot";
import store from "../../../store";
import { CLIENT_STORE, MIN_VISITOR_DURATION } from "../constants/other";
import { COUNT_VISITOR_INTERACTION } from "../stores/Client/constants";

(() => {
  const count_visitor_interation = (interaction_type) =>
    store.dispatch(
      `${CLIENT_STORE}/${COUNT_VISITOR_INTERACTION}`,
      interaction_type
    );

  // Check if visitor clicks at least once
  document.onclick = () => {
    count_visitor_interation("has_clicked")

    document.onclick = () => {}
  };

  // Check if visitor stays for at least MIN_VISITOR_DURATION
  setTimeout(
    () => count_visitor_interation("has_stayed_over_min_period"),
    MIN_VISITOR_DURATION
  );

  // Check if visitor is a not robot
  if (!is_bot(navigator.userAgent))
    count_visitor_interation("is_not_common_bot");
})()
